











































































































































































































































































































































// --- Vue & Template imports ---
import { Component, Vue } from 'vue-property-decorator';
import ConfirmActionDialog from '@/components/app/ConfirmActionDialog.vue';

// --- Models ---
import { Shoot } from '@/models/Shoot.model';
import { User } from '@/models/User.model';

// --- Services ---
import AppService from '@/services/app';
import AuthService from '@/services/auth';
import ShootService from '@/services/shoot';

// --- Third Party imports ---
import dayjs from 'dayjs';
import Hashids from 'hashids';

@Component({
  components: {
    ConfirmActionDialog,
  },
})
export default class ShootListing extends Vue {
  hashids = new Hashids(process.env.VUE_APP_HASHIDS_SALT);

  // used by Filter Area
  search: string = '';

  // used by No Data Slot
  isLoading: boolean = true;

  // used by Item Dialog for Create/Update
  showItemDialog: boolean = false;
  itemDialogAvailable: boolean = true;
  title: string = '';
  clickedItem: Shoot = {
    id: 0,
    name: '',
    shootTypeId: 0,
    shootType: '',
    regionId: 1,
    region: '',
    startDate: '',
    endDate: '',
    canDelete: false,
  };

  clickedProducer: User = {
    firstName: '',
    lastName: '',
    email: '',
    registrationTypeId: 0,
    registrationType: '',
    userTypeId: 0,
    userType: '',
    avatarFilename: '',
    cellphoneCountryRegion: 'ZA',
    cellphoneCountryDialCode: '',
    cellphoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    postalCode: '',
    country: 'South Africa',
    regionId: 0,
    region: '',
    addressFilename: '',
    idNumber: '',
    idFilename: '',
    hasUpcomingShoots: false,
  };
  
  valid: boolean = false;

  // used by Date Range Picker for Shoot Date
  today: string = dayjs().format('YYYY-MM-DD');
  datePicker: boolean = false;
  dateRange: string[] = [];
  datePickerHeader: string = '';
  dateRangeFormatted: string = '';
  totalDays: string = '';

  // used by Confirm Dialog for Delete
  confirmActionDelay: boolean = false;
  dialogContent: string = '';
  action: string = '';
  showConfirmActionDialog: boolean = false;
  confirmActionDialogAvailable: boolean = false;

  rules = {
    required: (value: string) => value != null && !!value.toString().trim() || '',
    requiredNumber: (value: number) => value != null && value !== 0 || '',
  };

  clicked: boolean = false;

  // #region Functions to display list data on page load
    headers() {
      return [
        {
          text: 'Type',
          value: 'shootType',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Date',
          value: 'startDate',
        },
        {
          sortable: false,
          text: '',
          value: 'action',
          align: 'end',
        },
      ];
    }

    customFilter(column: string, search: string, item: Shoot) {
      let result: boolean = false;
      
      if (search === '') {
        result = true;
      } else {
        result = item.shootType.toLowerCase().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          this.$store.getters['app/formattedDateRange'](item.startDate, item.endDate, true).toLowerCase().includes(search.toLowerCase());
      }

      return result;
    }

    customSort(items: any[], sortBy: string[], sortDesc: boolean[], locale: string) {
      // If the result is negative, a is sorted before b.
      // If the result is positive, b is sorted before a.
      // If the result is 0, no changes are done with the sort order of the two values.

      switch (sortBy[0]) {
        case 'shootType': 
          if (sortDesc[0]) {
            items.sort((a: Shoot, b: Shoot) => a.shootType.localeCompare(b.shootType));
          } else {
            items.sort((a: Shoot, b: Shoot) => b.shootType.localeCompare(a.shootType));
          }
          break;

        case 'name': 
          if (sortDesc[0]) {
            items.sort((a: Shoot, b: Shoot) => a.name.localeCompare(b.name));
          } else {
            items.sort((a: Shoot, b: Shoot) => b.name.localeCompare(a.name));
          }
          break;

        case 'startDate': 
          if (sortDesc[0]) {
            items.sort((a: Shoot, b: Shoot) => this.$store.getters['app/formattedDateRange'](a.startDate, a.endDate, true).localeCompare(this.$store.getters['app/formattedDateRange'](b.startDate, b.endDate, true)));
          } else {
            items.sort((a: Shoot, b: Shoot) => this.$store.getters['app/formattedDateRange'](b.startDate, b.endDate, true).localeCompare(this.$store.getters['app/formattedDateRange'](a.startDate, a.endDate, true)));
          }
          break;
      }

      return items;
    }

    async beforeMount() {
      await AppService.listRegions(false);
      await ShootService.listTypes(false);
      await this.listShoots();
      await this.getUserProfile();
    }

    async getUserProfile() {
      try {
        const response: string = await AuthService.getProfile();

        if (response) {
          // something broke
          AppService.errorHandler(response);
        } else {
          this.clickedProducer = this.$store.getters['users/loggedInUser'].profile;
        }
      } catch (error) {
        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('Shoot Listing.vue - getUserProfile - 397 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }
      }
    }

    async listShoots() {
      this.isLoading = true;

      await ShootService.listAll(true)
        .then((response) => {
          if (response && response !== 'No Data Found') {
            AppService.errorHandler(response);
          }
        })
        .finally(() => { 
          this.isLoading = false;
        });
    }

    viewItem(item: Shoot) {
      this.$router.push({ name: 'Shoot Detail', params: { id: '' + this.hashids.encode(item.id) } } ); 
    }
  // #endregion

  // #region Functions required for the Item Dialog for Create
    infoDialogClose() {
      this.showItemDialog = false;

      setTimeout(() => {
        this.itemDialogAvailable = false;
      }, 200);
    }
  
    launchNewItemDialog() {
      this.clickedItem = {
        id: 0,
        name: '', 
        shootTypeId: 0,
        shootType: '',
        regionId: this.$store.getters['users/loggedInUser'].profile.regionId, // always default to producer region
        region: '',  
        startDate: '',
        endDate: '',
        canDelete: false,
      };

      this.valid = false;
      this.title = 'Add New Shoot';
      this.datePickerHeader = 'Select Start Date';
      this.dateRange = [];
      this.dateRangeFormatted = '';
      this.totalDays = '';

      // Reset validation on form
      if (this.$refs.itemForm) {
        ((this.$refs.itemForm) as any).resetValidation();
      }

      this.itemDialogAvailable = true;
      this.showItemDialog = true;
    }

    onDatePickerToggle(opened: boolean) {
      if (!opened) {
        if (this.dateRange.length === 1) {
          this.datePickerHeader = 'Select Start Date';
          this.dateRange.push(this.dateRange[0]);
        }
      }
    }

    getMinimumDate() {
      return dayjs(Date()).add(4, 'day').format('YYYY-MM-DD');
    }

    shootDateInput() {
      // Check if daterange has start & end date, dateRange will contain only first date if no end date
      if (this.dateRange.length === 1) {
        // single date selection
        // assign date variables to clickedItem because we need to calculate totalDays on them
        this.clickedItem.startDate = this.dateRange[0];
        this.clickedItem.endDate = this.dateRange[0];
      } else {
        // Check if start & end date is actually after each other, else swap them
        if (dayjs(this.dateRange[0]).isAfter(this.dateRange[1])) {
          const temp = this.dateRange[0];
          this.dateRange[0] = this.dateRange[1];
          this.dateRange[1] = temp;
        }

        // assign date variables to clickedItem because we need to calculate totalDays on them
        // if this is not assigned, totalDays calculations malfunctions because the end date is not selected
        // when selecting the first date
        this.clickedItem.startDate = this.dateRange[0];
        this.clickedItem.endDate = this.dateRange[1];
      }

      // Set daterange formatted value for input field
      this.dateRangeFormatted = this.$store.getters['app/formattedDateRange'](this.clickedItem.startDate, this.clickedItem.endDate, false);
      // Calculate total Days for disabled total days input field (user info only)
      this.totalDays = this.$store.getters['app/totalBookingDays'](this.clickedItem.startDate, this.clickedItem.endDate);
      // Update the custom header with the formatted date and total days for feedback to the user on
      // what is actually selected
      this.datePickerHeader = this.$store.getters['app/formattedDateRange'](this.clickedItem.startDate, this.clickedItem.endDate, true); 
    }

    doCreate() {
      this.clicked = true;

      // Ensure format of dates are consistent
      ShootService.create({
        shootTypeId: this.clickedItem.shootTypeId,
        name: this.clickedItem.name,
        regionId: this.clickedItem.regionId,
        startDate: dayjs(this.clickedItem.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(this.clickedItem.endDate).format('YYYY-MM-DD'),
      })
      .then((response) => {
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            this.listShoots();
            this.infoDialogClose();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }
      })
      .catch((error) => {
        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('ShootListing.vue - doCreate - 524 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }
      })
      .finally(() => { this.clicked = false; });
    }
  // #endregion

  // #region Functions required for the Confirm Action Dialog for Delete
    launchDeleteItemDialog(item: Shoot) {
      this.clickedItem = item;

      this.action = 'DELETE';
      this.dialogContent = 'You are about to <span style="font-weight: 700; color: #C01212">delete</span> your '
        + '<br/><b>' + this.clickedItem.name  + '</b> Shoot.'
        + '<br/><br/><b>This will immediately delete ALL bookings for this item.</b>'
        + '<br/><br/> Are you sure?';

      this.confirmActionDialogAvailable = true;
      this.showConfirmActionDialog = true;
    }

    ConfirmActionDialog_cancel() {
      this.showConfirmActionDialog = false;

      setTimeout(() => {
        this.confirmActionDialogAvailable = false;
      }, 200);
    }

    ConfirmActionDialog_doConfirmed() {
      if (this.action === 'DELETE') {
        this.doDelete();
      }
    }

    async doDelete() {
      try {
        this.confirmActionDelay = true;
        const response = await ShootService.delete({id: this.clickedItem.id});
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.listShoots();
            this.ConfirmActionDialog_cancel();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('Shoot_Listing.vue - doDelete - 717 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.confirmActionDelay = false;
      }
    }
  // #endregion
}
